<template>
  <div>
    <div class="hero-main platinum-layout white-sec" :style="style">
      <div class="container">
        <div class="row row-reverse align-items-center">
          <div class="col-sm-12 col-md-6" data-wow-delay="0.5s">
            <div class="treasury-animation">
              <div class="platinum-move-1" />
              <div class="platinum-move-2" />
              <div class="platinum-move-3" />
              <div class="platinum-move-4" />
              <div class="platinum-move-5" />
              <div class="top-part">
                <div class="coin-icon" />
              </div>
              <div class="base">
                <div class="lines">
                  <span class="l-1" />
                  <span class="l-2" />
                  <span class="l-3" />
                  <span class="l-4" />
                  <span class="l-5" />
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-6 mobile-center">
            <h1>Let's <span>build</span> something <span>together!</span></h1>
            <p class="lead"><span class="emphasized">Creativity involves breaking out of expected patterns in order to look at things in a different way.</span> - "Edward de Bono"</p>
            <div class="hero-btns">
              <a href="#explore" class="btn el-btn">Explore</a>
              <a href="" class="btn el-btn" @click.prevent="$router.push('/treasury')">Check Treasury</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Hero Section End -->
    <div id="explore" class="token-sale p-tb c-l">
      <div class="container mt-6">
        <div class="text-center"><h2 class="section-heading">Funding <span>Allocation</span></h2></div>
        <div class="sub-txt text-center">
          <p>Our incubator allocation is presented below.</p>
        </div>
        <div class="row">
          <div class="col-md-12">
            <h3><span class="strong">Versatility</span></h3>
            <p>Elros is built to be flexible, to maximize revenue generating opportunities for the project. At the core of this flexibility lies the <span class="strong">Incubator.</span></p>

            <p>
              We have assembled a roster of <span class="strong">skilled people</span>, each of whom can provide services. A list of these services will soon be available for <span class="strong">external clients</span>. Elros will serve as the <span class="strong">bridge</span> between clients and service providers. Every time someone uses a service, they will pay a commission, which will go into the Treasury and thus generate income for the project.
            </p>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-md-12">
            <h3><span class="strong">Training for the future</span></h3>
            <p>Additionally, Elros aims to train people to provide services in the future. Training has already started for some of our future service providers in the form of a <span class="strong">web development course</span>.</p>
            <p>The price of this type of comprehensive training can usually run into thousands of dollars. We provide it to Elros holders for <span class="strong">free!</span></p>
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-md-12">
            <h3><span class="strong">Meet our Experts </span></h3>
            <div class="row mt-4">
              <div class="col-md-3 wow fadeInUp" data-wow-delay="0.1s">
                <div class="team-card text-center">
                  <img :src="kd" alt="Team Placeholder" @click="$router.push('services/TraderKyd')">
                  <h4 @click="$router.push('services/TraderKyd')">@TraderKyd</h4>
                  <span>Web Developer</span>
                </div>
              </div>
              <div class="col-md-3 wow fadeInUp" data-wow-delay="0.1s">
                <div class="team-card text-center">
                  <img :src="sp" alt="Team Placeholder" @click="$router.push('services/StepanZe1')">
                  <h4 @click="$router.push('services/StepanZe1')">@StepanZe1</h4>
                  <span>Graphics</span>
                </div>
              </div>
              <div class="col-md-3 wow fadeInUp" data-wow-delay="0.3s">
                <div class="team-card text-center">
                  <img :src="zsu" alt="Team Placeholder" @click="$router.push('services/DesignerQueen')">
                  <h4 @click="$router.push('services/DesignerQueen')">@DesignerQueen</h4>
                  <span>Graphic Designer & Illustrator</span>
                </div>
              </div>
              <div class="col-md-3 wow fadeInUp" data-wow-delay="0.3s">
                <div class="team-card text-center">
                  <img :src="taby" alt="Team Placeholder" @click="$router.push('services/TaBy93')">
                  <h4 @click="$router.push('services/TaBy93')">@TaBy93</h4>
                  <span>Web Designer & Developer | Wix Partner</span>
                </div>
              </div>
              <div class="col-md-3 wow fadeInUp" data-wow-delay="0.3s">
                <div class="team-card text-center">
                  <img :src="prinxxe" alt="Team Placeholder" @click="$router.push('services/prinxxe')">
                  <h4 @click="$router.push('services/prinxxe')">@prinxxe</h4>
                  <span>Moderator</span>
                </div>
              </div>
              <div class="col-md-3 wow fadeInUp" data-wow-delay="0.3s">
                <div class="team-card text-center">
                  <img :src="kevin" alt="Team Placeholder" @click="$router.push('services/LaunchWellKevin')">
                  <h4 @click="$router.push('services/LaunchWellKevin')">@LaunchWellKevin</h4>
                  <span>Content Creator & Communications Expert</span>
                </div>
              </div>
              <div class="col-md-3 wow fadeInUp" data-wow-delay="0.3s">
                <div class="team-card text-center">
                  <img :src="zazi" alt="Team Placeholder" @click="$router.push('services/ZaZi')">
                  <h4 @click="$router.push('services/ZaZi')">@ZaZi</h4>
                  <span>Copywriter & Content Creator</span>
                </div>
              </div>
              <div class="col-md-3 wow fadeInUp" data-wow-delay="0.3s">
                <div class="team-card text-center">
                  <img :src="ricky" alt="Team Placeholder" @click="$router.push('services/cry_pto01')">
                  <h4 @click="$router.push('services/cry_pto01')">@cry_pto01</h4>
                  <span>Moderator & Shiller</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="sponsors" class="team-section p-b c-l">
          <div class="container">
            <div class="team-member text-center">
              <div class="text-center"><h2 class="section-heading">Meet The Projects We <span>Supported</span></h2></div>
              <div class="row">
                <div class="col wow fadeInUp" data-wow-delay="0.1s" :style="sponsorsStyle">
                  <div class="team">
                    <div class="team-inner">
                      <img :src="novacore" alt="team">
                      <h4>NovaCore</h4>
                    </div>
                    <div class="team-social">
                      <ul>
                        <li><a href="https://twitter.com/novacoreeth" target="_blank"><i class="fab fa-x-twitter" /></a></li>
                        <li><a href="https://t.me/novacore_eth" target="_blank"><i class="fab fa-telegram" /></a></li>
                        <li><a href="https://ncor.ai/" target="_blank"><i class="fa fa-globe" /></a></li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col wow fadeInUp" data-wow-delay="0.1s" :style="sponsorsStyle">
                  <div class="team">
                    <div class="team-inner">
                      <img :src="degenfactory" alt="team">
                      <h4>Degen Factory</h4>
                    </div>
                    <div class="team-social">
                      <ul>
                        <li><a href="https://twitter.com/TheFactoryDegen" target="_blank"><i class="fab fa-x-twitter" /></a></li>
                        <li><a href="https://t.me/TheDegenFactory" target="_blank"><i class="fab fa-telegram" /></a></li>
                        <li><a href="https://thedegenfactory.com/" target="_blank"><i class="fa fa-globe" /></a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue';
  import { nanoid } from 'nanoid';
  import { copyToClipboard } from '@/utils/copy-to-clipboard';
  import logo from '@/assets/png/black-gold.png';
  import backgroundBanner from '@/assets/jpg/banner-1.jpg';
  import diamondAnimationMobile from '@/assets/png/diamond-animation-mobile.png';
  import sp from '@/assets/png/sp.png';
  import hf from '@/assets/png/hf.png';
  import taby from '@/assets/png/taby-cartoon.png';
  import kd from '@/assets/png/kd.png';
  import zsu from '@/assets/png/zsu.png';
  import kevin from '@/assets/png/kevin.png';
  import prinxxe from '@/assets/png/prinxxe.png';
  import zazi from '@/assets/png/zazi.png';
  import ricky from '@/assets/png/ricky.png';
  import novacore from '@/assets/png/novacore.png';
  import degenfactory from '@/assets/jpg/degenfactory.jpg';

  export default Vue.extend({
    name: 'elros-incubator',
    data: () => ({
      logo,
      ca: '0x91c6d8B00C1e250963849DffD7B1CBb3342683aF',
      sp,
      hf,
      prinxxe,
      zazi,
      novacore,
      kd,
      ricky,
      zsu,
      taby,
      kevin,
      degenfactory,
      diamondAnimationMobile,
      sponsorsStyle1: { visibility: 'visible', animationDelay: '0.1s', animationName: 'fadeInUp' },
      style: { backgroundImage: `url(${backgroundBanner})` },
    }),
    mounted() {
      window.initIncubatorAnimation();
    },
    methods: {
      onCopy(txt) {
        copyToClipboard(txt);
        this.$toasts.toast({
          id: nanoid(),
          message: 'The link has been copied to clipboard',
          title: 'Success',
          intent: 'success',
        });
      },
    },
  });
</script>
<style>
.wallet-container {
  display: flex;
  flex-direction: column;
  margin: 15px 0;
  padding: 0 10px;
}

.wallet-container h4 {
  font-size: 18px;
  text-transform: uppercase;
}

.wallet-container .wallet {
  font-size: 12px;
  line-height: 1.8;
  text-transform: uppercase;
}

.wallet-container .wallet span {
  color: #eec05e;
  display: block;
  font-weight: bold;
}

.team-card {
  padding: 15px 0;
  width: 100%;
}

.team-card:hover {
  cursor: pointer;
}

.team-card img {
  border: 6px solid #ffffff;
  border-radius: 50%;
  box-shadow: 0 0 0 2px #eec05e;
  max-height: 230px;
  max-width: 230px;
}

.team-card h4 {
  color: #1d1d1d;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 8px;
  margin-top: 30px;
}
</style>
